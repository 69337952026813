import React from 'react'
import "../../Assets/Style/Pages/PasswordReEnter.css"
import FormPagesSvgStyle from '../../Components/FormPagesSvgStyle'
import SmileImg from "../../Assets/Images/emoji _smiling_face_with_smiling_eyes.png"
import qritLogo from "../../Assets/Images/Qrit logo transparent.png"

import InputBox from "../../Components/InputBox"
import { IoCheckmarkDoneCircle } from "react-icons/io5"
import { Toaster } from 'react-hot-toast'
import ActiveLoader from '../../Components/ActiveLoader'
const Body = (_props) => {
    return (
        <div className="passwordReenterSec">
            <Toaster />
            <ActiveLoader loaderStatus={_props?.loaderStatus} />
            <FormPagesSvgStyle imageOrder={1} text='Well done, Continue!' textSize={25} image={SmileImg} imageSize={[40, 40]} circleColor={["#0077FF", "#84BEFF", "#BEDBFD"]} />
            <form onSubmit={(e) => _props?.ChangePassword(e)} className="passwordChangeSec" >
                <InputBox isPassword={true} name='password' type='password' placeholder='Enter a new password' inputValue={_props?.changePassword.password} onInputChange={_props?.setChangePassword} inputLabel='Password' inputParse={_props?.changePassword} isRequired={true} minLength={6} />
                <div className="errSec">
                    <InputBox icon={(_props?.passwordFieldError === null && _props?.changePassword.password !== "" && _props?.changePassword.cPassword !== "" && _props?.changePassword.password === _props?.changePassword.cPassword) ? <IoCheckmarkDoneCircle size={28} fill='green' /> : null} isPassword={true} name='cPassword' type='password' placeholder='Re-Enter the password' inputValue={_props?.changePassword.cPassword} onInputChange={_props?.setChangePassword} inputLabel='Re-Enter' inputParse={_props?.changePassword} isRequired={true} minLength={6} />
                    <span>
                        {_props?.passwordFieldError}
                    </span>

                </div>
                <button disabled={(_props?.changePassword.password !== "" && _props?.changePassword.cPassword !== "" && _props?.changePassword.password === _props?.changePassword.cPassword) ? false : true} type='submit' title='submit button' className="submitBtn" >Change Password</button>


            </form>


            <FormPagesSvgStyle imageOrder={2} rotation={180} text='Made with love ❤️' textSize={13} image={qritLogo} imageSize={[80, 80]} circleColor={["#0077FFF0", "#00AEB93D", "#020C0D12"]} />
        </div>
    )
}

export default Body