import React from 'react'
import Header from '../../Components/Header'
import "../../Assets/Style/Pages/History.css"
import ListCard from '../../Components/ListCard'
const Body = () => {
    return (
        <div className="historyPage">
            <Header headerLabel='History' />
            <div className="historyPageBody">
                <ListCard type="history" name='Sarfii_raaz' id="ID67898683689" dayTime='Today 12:34 pm' description='1 water jar of 20 liter is added' />
                <ListCard type="history" address={"Gazi house, mohammadpur, newtown, 700135"} name='Sarfii_raaz' id="ID67898683689" dayTime='Today 12:34 pm' description='1 water jar of 20 liter is added' />
            </div>
        </div>
    )
}

export default Body