import React, { useState } from 'react'
import PropTypes from 'prop-types'
import "../Assets/Style/Components/EditableInput.css"
import { MdOutlineModeEdit } from "react-icons/md"
const EditableInput = _props => {
    const [editableEnable, setEditableEnable] = useState(false)
    const EnableEdit = (id) => {
        setEditableEnable(true)
        if (editableEnable === true && _props?.isEdit === true) { document.getElementById(id).focus() }
    }
    return (
        <>
            <div className="editableInputSec">
                <label htmlFor={_props?.name}>{_props?.label}</label>
                {
                    (_props?.type === "text" || _props?.type === "number" || _props?.type === "email" || _props?.type === "password") ?

                        (editableEnable && _props?.isEdit) ?
                            <input id={_props?.name} type={_props?.type} name={_props?.name} value={_props?.inputValue} placeholder={_props?.placeholder} onChange={(e) => _props?.onInputChange({ ..._props?.parser, [e.target.name]: e.target.value })} minLength={_props?.minLength} maxLength={_props?.maxLength} className={_props?.isEdit ? "editableInput" : "unEditAble editableInput"} /> :

                            <div className={_props?.isEdit ? "editableInputDiv" : "unEditAble editableInputDiv"}><span>
                                {_props?.inputValue}
                            </span>

                                {
                                    _props?.isEdit ? <button type='button' className='editableSecBtn' onClick={() => EnableEdit(_props?.name)}>
                                        <MdOutlineModeEdit size={20} color='var(--commonColor)' />
                                    </button>
                                        : ""
                                }
                            </div>
                        : ""
                }
                {
                    (editableEnable && _props.isEdit) ?
                        <button type='button' className='editableBtn' onClick={() => EnableEdit(_props?.name)}>
                            <MdOutlineModeEdit size={20} color='var(--commonColor)' />
                        </button>
                        : ""
                }
            </div>
        </>
    )
}

EditableInput.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onInputChange: PropTypes.func.isRequired,
    inputValue: PropTypes.string.isRequired,
    parser: PropTypes.object.isRequired,
    isEdit: PropTypes.bool
}
EditableInput.defaultProps = {
    isPassword: false,
    maxLength: "",
    minLength: "",
    isEdit: false
}
export default EditableInput