import React from 'react'
import Header from '../../Components/Header'
import "../../Assets/Style/Pages/Notification.css"
import ListCard from '../../Components/ListCard'
const Body = _props => {
    return (
        <div className='notificationPage'>
            <Header headerLabel='Notification' />
            <div className="notificationPageBody">
                <div className="notificationMarkBtnSec">
                    <button className="notificationAllReadBtn">
                        Mark as read
                    </button>
                </div>


                <ListCard dayTime='Today 12:34 pm' title="Daily update for today" description="1 water jar of 20 liter is added" />
                <ListCard dayTime='Yesterday 01:35 pm' title="Daily update for today" description="1 water jar of 20 liter is added" />


            </div>


        </div>
    )
}

Body.propTypes = {}

export default Body