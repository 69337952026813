import React, { useState } from 'react'
import Body from './Body'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import auth from '../../Routes/Auth/Auth';
import toast from 'react-hot-toast';

const Forgot = () => {
    const navigation = useNavigate();
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [forgotPassword, setForgotPassword] = useState({
        email: "",
        type: "forgetPass"
    })
    const handleForgot = (e) => {
        e.preventDefault()
        setLoaderStatus(true)
        axios.post(auth.forgotOtp, forgotPassword).then((res) => {
            setLoaderStatus(false)
            if (res.status === 202) {
                toast.error(res.data.error);
            } else {
                toast.success("Otp send successfully")
                navigation("/otp", { state: { ...forgotPassword } })
            }
        }).catch((err) => {
            setLoaderStatus(false)
            console.log(err);
            toast.error("Please try again later");
        })
    }


    const _this = {
        forgotPassword, setForgotPassword, handleForgot, loaderStatus
    }
    return (
        <Body {..._this} />
    )
}

export default Forgot