import React from 'react'
import PropTypes from 'prop-types'
import "../Assets/Style/Components/ActiveLoader.css"
import { LuLoader2 } from "react-icons/lu";

const ActiveLoader = _props => {
    return (
        _props?.loaderStatus &&
        <div className="activeLoader">
            <LuLoader2 size={30} color='var(--commonColor)' className='activeLoaderIcon' />
            <span>Please wait...</span>
        </div>
    )
}

ActiveLoader.propTypes = {
    loaderStatus: PropTypes.bool.isRequired
}

export default ActiveLoader
