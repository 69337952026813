import React, { useState } from 'react'
import Body from './Body'

const GetHelp = () => {
    const [helpData, setHelpData] = useState({
        email: "",
        query: ""
    })
    const _this = {
        helpData, setHelpData
    }
    return (
        <Body {..._this} />
    )
}

export default GetHelp