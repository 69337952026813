import React from 'react'
import PropTypes from 'prop-types'
import "../Assets/Style/Components/ListCard.css"
import { Link } from 'react-router-dom'
const ListCard = _props => {
    return (
        <>
            <div className="listcard">
                <div className="listCardDateTime">
                    {_props?.dayTime}
                </div>
                <div className="listCardUserSec">
                    <span>{_props?.name}</span>
                    <span>{_props?.id.slice(0, 5)}...</span>
                </div>
                {
                    _props?.type === "history" ?
                        <div className="listCardDescription">
                            {_props?.description}
                        </div>
                        : <div className="address">
                            {_props?.address}
                        </div>
                }
                {
                    _props?.type !== "history" ?
                        <Link to={_props?.navigatePath} state={_props?.id} className="listCardRedirector"></Link>
                        : ""
                }
            </div>
        </>
    )
}

ListCard.propTypes = {
    dayTime: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    address: PropTypes.string,
    navigatePath: PropTypes.string
}

export default ListCard