import React from 'react'
import PropsType from "prop-types"
const PaymentDisplayCard = (_props) => {
    return (
        <div className="CountAndPaymentCards">
            <div className="cardTitle">{_props?.cardTitle}</div>
            <div className="cardDetails">
                <div className="cardDetailsNumber">
                    {_props?.cardNumber}
                </div>
                <div className="cardDetailsUnit">
                    {_props?.cardUnit}
                </div>
            </div>
            <div className="cardDate">{_props?.date === "" ? "No " + _props?.cardTitle : "On " + _props?.date}</div>
        </div>
    )
}

PaymentDisplayCard.propsType = {
    cardTitle: PropsType.string.isRequired,
    dueNumber: PropsType.number.isRequired,
    cardUnit: PropsType.number.isRequired,
    date: PropsType.string.isRequired,

}

export default PaymentDisplayCard