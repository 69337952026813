import React, { useEffect, useState } from 'react'
import Body from './Body'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import auth from '../../Routes/Auth/Auth';
import toast from 'react-hot-toast';

const PasswordReEnter = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const locationData = location.state
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [changePassword, setChangePassword] = useState({
        password: "",
        cPassword: "",
        ...locationData
    });
    const [passwordFieldError, setPasswordFieldError] = useState(null)
    const ChangePassword = (e) => {
        e.preventDefault();
        setLoaderStatus(true);
        axios.post(auth.forgotPassword, changePassword).then((res) => {
            setLoaderStatus(false);
            if (res.status === 202) {
                toast.error(res.data.error);
            } else {
                toast.success("Password Changed Successfully");
                navigate("/login");
            }

        }).catch((err) => {
            toast.error("Please try again later");
            console.log(err)
            setLoaderStatus(false);
        })
    }
    const _this = {
        changePassword, setChangePassword, passwordFieldError, setPasswordFieldError, ChangePassword, loaderStatus
    }

    useEffect(() => {
        if (changePassword.password !== changePassword.cPassword) {
            return setPasswordFieldError("Both Password should be same")
        }
        setPasswordFieldError(null)

        // eslint-disable-next-line
    }, [changePassword.cPassword])

    return (
        <Body {..._this} />
    )
}

export default PasswordReEnter