import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate()
    function deleteAllCookies() {
        document.cookie.split(';').forEach(cookie => {
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        });
    }
    useEffect(()=>{
        deleteAllCookies()
        navigate("/login")
        window.location.reload();
    },[navigate])
    return (
        <div>Login out....</div>
    )
}

export default Logout