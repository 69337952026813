import React from 'react'
import "../Assets/Style/Components/Header.css"
import { IoMdArrowBack } from "react-icons/io"
import PropTypes from "prop-types"
const Header = (_props) => {
    const BackBtn = () => {
        return window.history.length > 1 ? window.history.back() : ""
    }
    return (
        <>
            <div className="header">
                <button type='button' onClick={() => BackBtn()} className='backBtn'>
                    <IoMdArrowBack size={26} />
                </button>
                <h2 className="headerLabel">
                    {_props.headerLabel}
                </h2>
            </div>
        </>
    )
}

export default Header


Header.propTypes = {
    headerLabel: PropTypes.string.isRequired
}