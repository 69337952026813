import React, { useState } from 'react'
import Body from './Body'

const ChooseSupplier = () => {
    const [supplier, setSupplier] = useState({
        supplier: ""
    })
    const _this = {
        supplier, setSupplier
    }
    return (
        <Body {..._this} />
    )
}

export default ChooseSupplier