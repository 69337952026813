import React, { useEffect, useState } from 'react'
import Body from './Body'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'

const UserProfile = () => {
    const location = useLocation()
    const [customerProfile, setSustomerProfile] = useState(null)
    const token = document.cookie !== "" ? document.cookie.split(";")[0].split("token=")[1] : ""
    useEffect(() => {
        axios.get(auth.getCustomerProfile + "/" + location.state, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                setSustomerProfile(res.data.data.userInfo)
            }
        }).catch((err) => {
            console.log(err);
        })
    }, [location.state])
    const _this = { customerProfile }
    return (
        <Body {..._this} />
    )
}

export default UserProfile