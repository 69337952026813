import React from 'react'
import "../../Assets/Style/Pages/ProfileEdit.css"
import Header from '../../Components/Header'
import profileImg from "../../Assets/Images/profileLogoTest.png"
import { MdOutlineCameraAlt } from "react-icons/md"
import EditableInput from '../../Components/EditableInput'
import ActiveLoader from '../../Components/ActiveLoader'
import { Toaster } from 'react-hot-toast'
const Body = (_props) => {
    return (
        <>
            <Toaster />
            <ActiveLoader loaderStatus={_props?.loader} />
            <div className="profileEditPage">
                <Header headerLabel='My Account' />
                <div className="profileEditBody">
                    <form className="profileUpdateForm" onSubmit={(e) => _props?.updateSupplierProf(e)}>
                        <div className="profileHeader">
                            <img src={profileImg} alt="" />
                            <button className="pictureSelectBtn">
                                <MdOutlineCameraAlt size={20} color='black' />
                            </button>
                        </div>
                        <div className="profileUpdateInputs">
                            <div className="userIdHeader">
                                {_props?.updateProfile && _props?.updateProfile.distributorId}
                            </div>

                            <div className="inputsSection">
                                <EditableInput name="name" type="text" inputValue={_props?.updateProfile && _props?.updateProfile.name} onInputChange={_props?.setUpdateProfile} placeholder="Choose your supplier" maxLength={14} minLength={6} parser={_props?.updateProfile} label="Supplier Name" isEdit={true} />

                                <EditableInput name="storeName" type="text" inputValue={_props?.updateProfile && _props?.updateProfile.storeName} onInputChange={_props?.setUpdateProfile} placeholder="Store Name" parser={_props?.updateProfile} label="Supplier store name" isEdit={true} />

                                {/* <EditableInput name="name" type="text" inputValue={_props?.updateProfile.name} onInputChange={_props?.setUpdateProfile} placeholder="Enter your name" parser={_props?.updateProfile} label="Name" isEdit={true} /> */}
                                <EditableInput name="email" type="email" inputValue={_props?.updateProfile && _props?.updateProfile.email} onInputChange={_props?.setUpdateProfile} placeholder="Enter your email" parser={_props?.updateProfile} label="Email" isEdit={true} />
                                <EditableInput name="phone" type="number" inputValue={_props?.updateProfile && _props?.updateProfile.phone} onInputChange={_props?.setUpdateProfile} placeholder="Enter your phone no" parser={_props?.updateProfile} label="Phone No" isEdit={true} />
                                <EditableInput name="wpNumber" type="number" inputValue={_props?.updateProfile && _props?.updateProfile.wpNumber} onInputChange={_props?.setUpdateProfile} placeholder="Enter your whatsapp no" parser={_props?.updateProfile} label="Whatsapp No" isEdit={true} />


                                <EditableInput name="price" type="text" inputValue={_props?.updateProfile && _props?.updateProfile.price} onInputChange={_props?.setUpdateProfile} placeholder="Enter your product price" parser={_props?.updateProfile} label="Price per product" isEdit={true} />
                                <EditableInput name="Qty" type="text" inputValue={_props?.updateProfile && _props?.updateProfile.Qty} onInputChange={_props?.setUpdateProfile} placeholder="Enter product Quantity in liter" parser={_props?.updateProfile} label="Product quantity in liter" isEdit={true} />
                                <EditableInput name="address" type="text" inputValue={_props?.updateProfile && _props?.updateProfile.address} onInputChange={_props?.setUpdateProfile} placeholder="Enter your address" parser={_props?.updateProfile} label="Address" isEdit={true} />
                                <EditableInput name="pincode" type="text" inputValue={_props?.updateProfile && _props?.updateProfile.pincode} onInputChange={_props?.setUpdateProfile} placeholder="Enter your address" parser={_props?.updateProfile} label="Pincode" isEdit={true} />

                                <button className="submitBtn">
                                    Update
                                </button>
                            </div>


                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default Body