import React from 'react'
import "../../Assets/Style/Pages/UserList.css"
import Header from '../../Components/Header'
import ListCard from "../../Components/ListCard" 
import userNotFoundImg from '../../Assets/Images/userNotFound.png'
const Body = (_props) => {

    return (
        <>
            <div className="customersPage">
                <Header headerLabel='Users' />
                <div className="customerPageBody">
                    {
                        _props?.userProfiles && _props?.userProfiles.map((data) => {
                            return <ListCard type='users' dayTime={data.createdAt} name={data.name} id={data._id} address={data.address} navigatePath={"/customers/profile"} key={data._id} />
                        })
                    }

                    {(_props?.userProfiles?.length === 0 ?
                        <div className='userNotFound'>
                            <img src={userNotFoundImg} alt="userNotFoundImg" />
                            <p>No User Found</p>
                        </div>
                        : ""
                    )}



                </div>
            </div>


        </>
    )
}

export default Body