import React, { useEffect, useState } from 'react'
import "../../Assets/Style/Pages/Home.css"
import { Link } from 'react-router-dom'
import ProfileHeader from '../../Components/ProfileHeader'
import ProfileTestImg from "../../Assets/Images/profileLogoTest.png"
import ViewAllUser from "../../Assets/Images/Account-cuate.png"
import ProfileBanner from "../../Assets/Images/userProfileAddsBanner.jpeg"
import FilterWaterDetails from '../../Components/FilterWaterDetails'
import { FaArrowRightLong } from "react-icons/fa6"
import PerDayStatus from '../../Components/PerDayStatus'
import DuePaymentCard from '../../Components/DuePaymentCard'
import { IoIosLogOut } from "react-icons/io"
const Body = (_props) => {
    // per day filter due and also paid
    const [transactionHistory, setTransactionHistory] = useState({
        tillNow: 0,
        today: 0,
        date: new Date(),
        qty: 0,
        todayAmount: 0
    })
    useEffect(() => {
        let totalAmount = 0;
        let perDayAmount = 0;
        let todayTotalAmount = 0

        let qty = 0;
        let toDay = new Date()
        toDay.setHours(0, 0, 0, 0)
        _props?.transactions && _props?.transactions.map((data) => {
            data.TransactionInf.map((trans) => {

                if (trans.status === "pending") {
                    totalAmount += trans.amount
                    if (toDay.toString() === new Date(trans.date).toString()) {
                        perDayAmount += trans.amount
                    }
                }
                if (toDay.toString() === new Date(trans.date).toString()) {
                    qty += trans.qty
                    todayTotalAmount += trans.amount
                }
            })
        })
        setTransactionHistory({ ...transactionHistory, tillNow: totalAmount, today: perDayAmount, qty: qty, todayAmount: todayTotalAmount })
    }, [_props])
    const [dateChange, setDateChange] = useState(new Date())
    const [filterTransactions, setFilterTransactions] = useState({
        qty: 0,
        amount: 0
    })

    // filter by date
    useEffect(() => {
        let qty = 0
        let amount = 0

        _props?.transactions && _props?.transactions.map((data) => {
            data.TransactionInf.map((trans) => {
                if ((new Date(dateChange).getMonth() === new Date(trans.date).getMonth()) && (new Date(dateChange).getFullYear() === new Date(trans.date).getFullYear())) {
                    qty += trans.qty
                    amount += trans.amount
                }
            })
        })
        setFilterTransactions({ ...filterTransactions, qty, amount })

    }, [dateChange, _props])

    const [totalAmountQty, setTotalAmountQty] = useState({
        amount: 0,
        qty: 0
    })

    useEffect(() => {

        let qty = 0
        let amount = 0

        _props?.transactions && _props?.transactions.map((data) => {
            data.TransactionInf.map((trans) => {
                qty+=trans.qty
                amount+=trans.amount
            })
        })
        setTotalAmountQty({...totalAmountQty,qty,amount});

    }, [_props])


    return (
        _props?.profile &&
        <>
            <div className="userHomePage">
                <ProfileHeader profType="supplier" profileIcon={ProfileTestImg} profileName={_props?.profile && _props?.profile.name} />
                <div className="userProfileBanner">
                    <img src={ProfileBanner} alt="banner" />
                </div>
                <PerDayStatus qty={transactionHistory.qty} amount={transactionHistory.todayAmount} />
                <DuePaymentCard totalDue={transactionHistory.tillNow} perDayDue={transactionHistory.today} />


                <FilterWaterDetails dateChange={setDateChange} date={dateChange} qty={filterTransactions.qty} amount={filterTransactions.amount} />
                <div className="totalWaterCan">
                    <div className="totalWaterCanCard">
                        <div className="totalWaterCanDetails">
                            <label htmlFor="">Total Count</label>
                            <div className="waterCanCount">
                                <div className="numberOfWaterCan">{totalAmountQty.qty}</div>
                                <div className="unitOfWaterCan">P</div>
                            </div>
                        </div>
                        <div className="totalWaterCanDetails">
                            <label htmlFor="">Total Payment</label>
                            <div className="waterCanCount">
                                <div className="numberOfWaterCan">{totalAmountQty.amount}</div>
                                <div className="unitOfWaterCan">₹</div>
                            </div>
                        </div>

                    </div>
                </div>

                {/* user history button and some quote  */}
                <div className="historyVisitOuterSection">
                    <div className="historyVisitInnerCard">
                        <div className="historySecInnerCardLeft">
                            <div className="historySecUserName">
                                All users list
                            </div>
                            <div className="historySecQuote">
                                You can see all user list individually, separately</div>
                            <Link className="historyVisitBtn" to={"customers"}>
                                <span>See User</span>
                                <FaArrowRightLong size={20} color="var(--commonColor)" />
                            </Link>
                        </div>
                        <div className="historySecInnerCardImg">
                            <img src={ViewAllUser} alt="" />
                        </div>
                    </div>
                </div>

                <div className="getHelpHomePageSection">
                    <Link className="getHelpInnerCard" to={"/dues"}>
                        <span>Due Payment</span>
                        <FaArrowRightLong size={25} color='gray' />
                    </Link>
                    <Link className="getHelpInnerCard" to={"/help"}>
                        <span>Get help</span>
                        <FaArrowRightLong size={25} color='gray' />
                    </Link>
                    <Link to={"/logout"} className="getHelpInnerCard" style={{ background: "red" }}>
                        <span style={{ color: 'white' }}>Logout</span>
                        <IoIosLogOut size={25} color='white' />
                    </Link>
                </div>
            </div>
        </>
    )
}

export default Body