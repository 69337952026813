import React from 'react'
import Header from '../../Components/Header'
import "../../Assets/Style/Components/GetHelp.css"
import InputBox from '../../Components/InputBox'
import Img from "../../Assets/Images/ContactPageImg.png"
const Body = (_props) => {
    console.log(_props)
    return (
        <>
            <div className="getHelpPage">
                <Header headerLabel='Get Help' />
                <div className="getHelpPageBody">
                    <form className="getHelpForm">
                        <InputBox name='email' type='email' placeholder='Enter your email id' isrequired={true} inputLabel='Email' inputValue={_props?.helpData.email} inputParse={_props?.helpData} onInputChange={_props?.setHelpData} />
                        <InputBox longText={true} name='query' type='text' placeholder='Enter your query' isrequired={true} inputLabel='Query' inputValue={_props?.helpData.query} inputParse={_props?.helpData} onInputChange={_props?.setHelpData} />
                        <button className="submitBtn">Submit</button>
                    </form>
                    <div className="qritContactDetailsSection">
                        <div className="contactDetailsCard">
                            <div className="contactType">
                                Our tech support mail
                            </div>
                            <a href="mailto:qritnow.in@gmail.com">qritnow.in@gmail.com</a>
                        </div>
                        <div className="contactDetailsCard">
                            <div className="contactType">
                            Our whats app number
                            </div>
                            <a href="https://wa.me/+917063376516">7063376516</a>
                        </div>
                    </div>
                    <div className="getHelpPageImgSec">

                    <img src={Img} alt="" className='getHelpPageImg' />
                    </div>
                </div>
            </div>


        </>
    )
}

export default Body