import React, { useEffect, useState } from 'react'
import Body from './Body'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'
// import { useLocation } from 'react-router-dom'
// import AudioSound from "../../Assets/Audio/beep.mp3"
const UserHome = () => {
    const token = document.cookie !== "" ? document.cookie.split(";")[0].split("token=")[1] : ""
    const userId = document.cookie !== "" ? document.cookie.split(";")[1].split("id=")[1] : ""

    const [profile, setProfile] = useState(null)
    const [transactions, setTransactions] = useState(null)

    // const location = useLocation()
    // const audioRef = useRef()
    // useEffect(() => {
    //     if (location.state !== "" && location.state.data !== null) {
    //         audioRef.current.play()
    //     }
    // }, [location])
    useEffect(() => {
        axios.get(auth.getProfile + "/" + userId, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((res) => {
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                setProfile(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        })
    }, [token, userId])
    useEffect(() => {
        axios.post(auth.getTransaction,{distributorId:userId}, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((res) => {
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                setTransactions(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        })
    }, [token,userId])



    const _this = { profile, transactions }
    return (
        <>
            <Body {..._this} />
            {/* <audio src={AudioSound} ref={audioRef} style={{ visibility: "none" }}></audio> */}

        </>
    )
}

export default UserHome