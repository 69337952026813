const auth = {
    signup: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/signup-verify",
    signupOtp: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/signup",
    login: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/login",

    resendOtp: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/resend-otp",
    forgotOtp: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/forget-password/send-otp",
    forgotPassword: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/forget-password",
    getProfile: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/get-distributor-profile",
    getTransaction: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/get-all-transaction",
    editProfile: process.env.REACT_APP_BACKEND_URL + "/api/v1/user/profile-edit",

    qrScanner: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/scan-qr",
    pendingTransaction: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/get-pending-transaction",
    updatePendingDues: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/update-transaction-amount",
    profileUpdate: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/edit-profile",
    getCustomers: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/get-all-user",
    getCustomerProfile: process.env.REACT_APP_BACKEND_URL + "/api/v1/distributor/get-user",



}

export default auth;