import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { Modal, useMantineTheme } from '@mantine/core';
import doneImg from "../../Assets/Images/done.svg"
import beepSound from '../../Assets/Audio/beep.mp3'
import '../../Assets/Style/Components/modal/QrScanedSuccess.css'


const QrScanedSuccess = ({ openModel, setOpenModel }) => {
    const navigate = useNavigate()
    const theme = useMantineTheme();
    useEffect(() => {
        if (openModel) {
            const audio = new Audio(beepSound);
            audio.play();
        }
    }, [openModel]);
    
    return (
        <div>
            <Modal
                overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
                overlayOpacity={0.55}
                overlayBlur={3}
                opened={openModel}
                onClose={() => setOpenModel(false)}
            >
                <div className='successfullScannedContainer'>
                    <img src={doneImg} alt="done" />
                    <h2>Done!</h2>
                    <p>1 filled Water jar is successfully added</p>
                    <button onClick={() => { navigate('/') }}>Okay</button>
                </div>
            </Modal>

        </div>
    )
}

export default QrScanedSuccess
