import React from 'react'
import "../../Assets/Style/Pages/DuePayments.css"
import Header from '../../Components/Header'
import DuePaymentPageCard from '../../Components/DuePaymentPageCard'
import { Toaster } from 'react-hot-toast'
import ActiveLoader from '../../Components/ActiveLoader'
import transactionNotFounfImg from '../../Assets/Images/transactionNotFound.png'
const Body = (_props) => {
    return (
        _props?.pendingTrans !== null ? <>
            <Toaster />
            <ActiveLoader loaderStatus={_props?.loader} />
            <div className="duePaymentsPage">
                <Header headerLabel='Due Payments' />
                <div className="duePaymentsPageBody">
                    {
                        _props?.pendingTrans && _props?.pendingTrans.map((user) => {
                            return user && user.TransactionInf.map((data, index) => {
                                return user.user && <DuePaymentPageCard
                                    key={index}
                                    name={user.user.name}
                                    userId={user.user._id}
                                    id={data._id.toString().slice(0,5)+"..."}
                                    address={user.user.address}
                                    quantity={data.qty}
                                    amount={data.amount}
                                    date={data.date}
                                    updateDues={_props?.UpdateDues}
                                />
                            })
                        })
                    }
                    {
                        _props?.pendingTrans.length === 0 ?
                            <div className="blankMsgSec">
                                <img src={transactionNotFounfImg} alt="transactionNotFounfImg" />
                                Transaction Not Found
                            </div> : ""
                    }
                </div>
            </div>
        </> : <ActiveLoader loaderStatus={true} />
    )
}

export default Body