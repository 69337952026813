import React from 'react'
import Body from './Body'

const Notification = () => {
    const _this = {}
    return (
        <Body {..._this} />
    )
}

export default Notification