import React from 'react'
import PropTypes from 'prop-types'

const DuePaymentPageCard = _props => {
    return (
        _props && <div className="duesPageCard">
            <div className="duesPageHeader">
                <span>{new Date(_props?.date).toLocaleString("default", { month: 'long' })}</span>
                <span>{new Date(_props?.date).getDay()} th</span>
            </div>


            <div className="supplierClientSec">
                <div className="userNameAddress">
                    <span>{_props?.name}</span>
                    <span>{_props?.address}</span>
                </div>
                <div className="userId">{_props?.id}</div>
            </div>
            <div className="ammountAndBtn">
                <div className="ammountAndWaterQuantity">
                    <div className="duesAmount">
                        <span>{_props?.amount}</span>
                        <span>₹</span>
                    </div>
                    <span style={{ fontSize: "17px" }}>for</span>
                    <div className="waterCanQuantity">
                        <span>{_props?.quantity}</span>
                        <span>p</span>
                    </div>
                </div>
                <button type='btn' className="paidActionBtn" onClick={() => _props?.updateDues(_props?.userId)}>
                    Paid
                </button>
            </div>
        </div>
    )
}

DuePaymentPageCard.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired
}

export default DuePaymentPageCard