import React, { useEffect, useState } from 'react'
import Body from './Body'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

const Signup = () => {
    const navigate = useNavigate();
    const [formError, setFormError] = useState("")
    const [loader, setLoader] = useState(false)

    const [signup, setSignup] = useState({
        name: "",
        email: "",
        phone: "",
        wpNumber: "",
        storeName: "",
        Qty: "",
        price: "",
        address: "",
        password: "",
        cpassword: "",
        termCondition: false,
        pincode:"",
        type: "DistributorRegistration"
    })
    // validate password and confirm password 
    useEffect(() => {
        signup.password !== signup.cpassword ? setFormError("Password and confirm password must be same") : setFormError("")
    }, [signup])


    const signupHandel = (e) => {
        e.preventDefault();
        setLoader(true);
        axios.post(auth.signupOtp, signup).then((res) => {
            setLoader(false);
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                toast.success("Otp Send Successfully")
                navigate("/otp", { state: { ...signup } })
            }
        }).catch((err) => {
            setLoader(false);
            console.log(err);
        })

    }

    // assign all states to a single object and pass through a props
    const _this = {
        signup, setSignup, formError, setFormError, signupHandel, loader
    }
    return (
        <Body {..._this} />
    )
}

export default Signup
