import React, { useEffect, useState } from 'react'
import Body from './Body'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'

const UsersList = () => {
    const token = document.cookie !== "" ? document.cookie.split(";")[0].split("token=")[1] : ""
    const _id = document.cookie !== "" ? document.cookie.split(";")[1].split("id=")[1] : ""

    const [userProfiles, setUserProfiles] = useState(null)
    useEffect(() => {
        axios.get(auth.getCustomers + "/" + _id, { headers: { "Authorization": `Bearer ${token}` } }).then((res) => {
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                setUserProfiles(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        })
    }, [])
    const _this = { userProfiles }
    return (
        <Body {..._this} />
    )
}

export default UsersList