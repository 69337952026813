import React from 'react'
import "../../Assets/Style/Pages/Signup.css"
import Header from '../../Components/Header'
import InputBox from '../../Components/InputBox'
import { Link } from 'react-router-dom'
import ActiveLoader from '../../Components/ActiveLoader'
import { Toaster } from 'react-hot-toast'
const Body = (_props) => {
    return (
        <>
            <ActiveLoader loaderStatus={_props?.loader} />
            <Toaster />
            <Header headerLabel="Back" />
            <form className="signupForm" onSubmit={(e) => _props?.signupHandel(e)}>
                <span className='signupQuote'>Fill this form like your water bottle.</span>
                <InputBox
                    name="name"
                    type="text"
                    inputLabel="Name"
                    placeholder="Enter your name"
                    inputValue={_props?.signup.name}
                    onInputChange={_props?.setSignup}
                    inputParse={_props?.signup}
                    isrequired={true}
                />
                <InputBox
                    name="storeName"
                    type="text"
                    inputLabel="Store Name"
                    placeholder="Enter your store name"
                    inputValue={_props?.signup.storeName}
                    onInputChange={_props?.setSignup}
                    inputParse={_props?.signup}
                    isrequired={true}
                />

                <InputBox
                    name="email"
                    type="email"
                    inputLabel="Email"
                    placeholder="Enter your email"
                    inputValue={_props?.signup.email}
                    onInputChange={_props?.setSignup}
                    inputParse={_props?.signup}
                    isrequired={true}
                />
                <InputBox
                    name="phone"
                    type="number"
                    inputLabel="Phone No."
                    placeholder="Enter your mobile no."
                    inputValue={_props?.signup.phone}
                    onInputChange={_props?.setSignup}
                    inputParse={_props?.signup}
                    isrequired={true}
                />
                <InputBox
                    name="wpNumber"
                    type="number"
                    inputLabel="Whats app"
                    placeholder="Enter your whats app no"
                    inputValue={_props?.signup.wpNumber}
                    onInputChange={_props?.setSignup}
                    inputParse={_props?.signup}
                    isrequired={true}
                />
                <InputBox
                    name="price"
                    type="number"
                    inputLabel="Price per product"
                    placeholder="Enter your water price"
                    inputValue={_props?.signup.price}
                    onInputChange={_props?.setSignup}
                    inputParse={_props?.signup}
                    isrequired={true}

                />
                <InputBox
                    name="Qty"
                    type="number"
                    inputLabel="Product quantity in liter"
                    placeholder="Enter product quantity"
                    inputValue={_props?.signup.Qty}
                    onInputChange={_props?.setSignup}
                    inputParse={_props?.signup}
                    isrequired={true}
                />

                <InputBox
                    name="address"
                    type="text"
                    inputLabel="Address"
                    placeholder="Enter your address"
                    inputValue={_props?.signup.address}
                    onInputChange={_props?.setSignup}
                    inputParse={_props?.signup}
                    isrequired={true}
                />
                <InputBox
                    name="pincode"
                    type="number"
                    inputLabel="Pin Code"
                    placeholder="Enter your pin code"
                    inputValue={_props?.signup.pincode}
                    onInputChange={_props?.setSignup}
                    inputParse={_props?.signup}
                    isrequired={true}
                />
                <InputBox
                    name="password"
                    type="password"
                    isPassword={true}
                    inputLabel="Password"
                    placeholder="Enter strong password"
                    inputValue={_props?.signup.password}
                    onInputChange={_props?.setSignup}
                    inputParse={_props?.signup}
                    isrequired={true}
                />
                <InputBox
                    name="cpassword"
                    type="password"
                    isPassword={true}
                    inputLabel="Confirm Password"
                    placeholder="Re-Enter your password"
                    inputValue={_props?.signup.cpassword}
                    onInputChange={_props?.setSignup}
                    inputParse={_props?.signup}
                    isrequired={true}
                />
                <div className="formError">
                    {
                        _props?.formError
                    }
                </div>


                <div className="termCondition">
                    <input type="checkbox" name="termCondition" checked={_props?.signup.termCondition} onChange={(e) => _props?.setSignup({ ..._props?.signup, [e.target.name]: e.target.checked })} id="termConditionInput" />
                    <div>
                        <span>I agree to the</span>
                        <Link> Terms & Conditions </Link> an<Link> Privacy Policy.</Link>
                    </div>
                </div>



                <button type="submit" className='submitBtn'
                    disabled={((_props?.signup.password === _props?.signup.cpassword) && _props?.signup.termCondition === true) ? false : true}>

                    Submit
                </button>




            </form>


        </>
    )
}

export default Body
