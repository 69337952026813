import React from 'react'
import PropTypes from 'prop-types'
import "../Assets/Style/Components/PerDayStatus.css"

const PerDayStatus = _props => {
    return (
        <>
            <div className="perDayStatusCardSec">
                <div className="perDayStatusCardBody">
                    <div className="perDayStatusCardHeader">
                        <div className="perDayStatusCardHeaderLeft">Today serve till now</div>
                        <div className="perDayStatusCardHeaderDate">{
                            new Date().toLocaleString('default', { month: 'long' })}
                            &nbsp;
                            {

                                new Date().getDate()
                            }
                        </div>
                    </div>
                    <div className="perDayStatusCardCounter">
                        <div className="mesurementCard">
                            <div className="measureNumber">{_props?.qty}</div>
                            <div className="measureUnit">p</div>
                        </div>
                        <div className="mesurementCard">
                            <div className="measureNumber">{_props?.amount}</div>
                            <div className="measureUnit">₹</div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

PerDayStatus.propTypes = {
    amount: PropTypes.number.isRequired,
    qty: PropTypes.number.isRequired
}

export default PerDayStatus