import React from 'react'
import "../Assets/Style/Components/ProfileHeader.css"
import { IoIosNotificationsOutline } from "react-icons/io"
import { MdOutlineQrCodeScanner } from "react-icons/md"
import PropType from "prop-types"
import { Link, useNavigate } from 'react-router-dom'

const ProfileHeader = (_props) => {
    const navigate=useNavigate()
    return (
        <div className="userHeader">
            <div className="profileName">
                <h2>Hi,</h2>
                <h2>{_props?.profileName.split(" ")[0]!==null?_props?.profileName.split(" ")[0]:_props?.profileName}</h2>
                
            </div>
            <div className="profileActionIcon supplierHeaderAction">
                {_props?.profType === "supplier" ?
                    <>
                        <button className='supplierProfileQrBtn' onClick={() => navigate("/scanner")}>
                            <MdOutlineQrCodeScanner size={30} color='black' />
                        </button>
                        <Link to="/editprofile">
                            <img src={_props?.profileIcon} alt="profileIcon" className="profileIcon" />
                        </Link>
                    </>

                    :
                    <button className='userProfileNotification'>
                        <IoIosNotificationsOutline size={40} color='black' />
                    </button>
                }
            </div>
        </div>
    )
}

export default ProfileHeader
ProfileHeader.defaultProps = {
    profileIcon: ""
}

ProfileHeader.profTypes = {
    profileName: PropType.string.isRequired,
    profType: PropType.string.isRequired
}