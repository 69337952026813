import React from 'react'
import Webcam from 'react-webcam'
import "../../Assets/Style/Pages/QrScanner.css"
import { Toaster } from 'react-hot-toast'
import QrScanedSuccess from '../../Components/modal/QrScanedSuccess'
const Body = (_props) => {
    return (
        <>
            <Toaster />
            <div className='qrScannerPage'>
                <Webcam
                    audio={false}
                    ref={_props?.webcamRef}
                    screenshotFormat="image/jpeg"
                    className='camView'
                    videoConstraints={_props?.videoConstraints}

                />
                <div className="scannerMsgBox">{_props?.error !== null ? _props?.error : ""}</div>
                <div className="backtoHomeBtn" onClick={() => window.history.back()} >
                    Back to home
                </div>
            </div>
            
            <QrScanedSuccess openModel={_props.openModel} setOpenModel={_props.setOpenModel} />
        </>
    )
}

export default Body