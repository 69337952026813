import React from 'react'
import PropTypes from 'prop-types'
import "../Assets/Style/Components/HomeDuePaymentCard.css"
import { Link } from 'react-router-dom'
const HomeDuePaymentCard = _props => {
    return (
        <div className="duePaymentsCardSec">
            <div className="duePaymentsCardBody">
                <div className="duePaymentHeader">
                    <Link type='btn' className='duePaymentLink' to={"/dues"}>
                        Due payments
                    </Link>
                </div>
                <div className="duePaymentsBody">
                    <div className="duecard">
                        <div className="dueAmmount"><span>{_props?.perDayDue}</span><span>₹</span></div>
                        <div className="dueTitle">Today</div>
                    </div>
                    <div className="duecard">
                        <div className="dueAmmount"><span>{_props?.totalDue}</span><span>₹</span></div>
                        <div className="dueTitle">Till now</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

HomeDuePaymentCard.propTypes = {
    totalDue: PropTypes.string.isRequired,
    perDayDue: PropTypes.string.isRequired
}

export default HomeDuePaymentCard