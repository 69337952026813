import React from 'react'
import "../../Assets/Style/Pages/UserProfile.css"
import Header from '../../Components/Header'
import TestProfileImg from "../../Assets/Images/profileLogoTest.png"
import FooterImg from "../../Assets/Images/userProfileFooterImg.jpeg"
import PaymentDisplayCard from '../../Components/PaymentDisplayCard'
const Body = (_props) => {
    return (
        _props?.customerProfile && <div className="userProfilePage">
            <Header headerLabel='Customer Profile' />
            <div className="userProfileBody">
                <div className="userProfileImageSection">
                    <img src={TestProfileImg} alt="" />
                    <h2>{_props?.customerProfile.user.name}</h2>
                </div>
                <div className="userProfileDetailsCard">
                    <div className="userProfileId">
                        {_props?.customerProfile.user._id}
                    </div>
                    <table className="userAnotherDetails">
                        <tbody>
                            <tr>
                                <td>Address</td>
                                <td>{_props?.customerProfile.user.address}</td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>
                                    <a href={`mailto:${_props?.customerProfile.user.email}`}>{_props?.customerProfile.user.email}</a>
                                </td>
                            </tr>
                            <tr>
                                <td>Phone</td>
                                <td><a href={`tel:${_props?.customerProfile.user.phone}`}>{_props?.customerProfile.user.phone}</a></td>
                            </tr>
                        </tbody>
                    </table>


                </div>
                <div className="userProfileShortCard">
                    <PaymentDisplayCard cardTitle={"Due count"} cardNumber={_props?.customerProfile.pending.count} cardUnit={"p"} date={_props?.customerProfile.pending.date} />
                    <PaymentDisplayCard cardTitle={"Due payment"} cardNumber={_props?.customerProfile.pending.amount} cardUnit={"₹"} date={_props?.customerProfile.pending.date} />
                </div>
                <div className="userProfileStatusSecondCard">
                    <div className="userProfileTotalCardInner">
                        <label htmlFor="">Total Count</label>
                        <div className="userProfileInnerCardDigits">
                            <span>{_props?.customerProfile.total.count}</span>
                            <span>p</span>
                        </div>
                    </div>
                    <div className="userProfileTotalCardInner">
                        <label htmlFor="">Total payment</label>
                        <div className="userProfileInnerCardDigits">
                            <span>{_props?.customerProfile.total.amount}</span>
                            <span>₹</span>
                        </div>
                    </div>
                </div>
                <div className="userProfileFooterSec">
                    <img src={FooterImg} alt="" />
                </div>
            </div>
        </div>
    )
}

export default Body