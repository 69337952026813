import React from 'react'
import "../../Assets/Style/Pages/Forgot.css"
import InputBox from "../../Components/InputBox"
import smileImg from "../../Assets/Images/emoji _smiling_face_with_smiling_eyes.png"
import qritLogo from "../../Assets/Images/Qrit logo transparent.png"
import FormPagesSvgStyle from '../../Components/FormPagesSvgStyle'
import { Toaster } from 'react-hot-toast'
import ActiveLoader from '../../Components/ActiveLoader'
const Body = (_props) => {
    return (
        <>
            <Toaster />
            <ActiveLoader loaderStatus={_props?.loaderStatus}/>
            <form className="forgotPage" onSubmit={(e) => _props?.handleForgot(e)}>
                <FormPagesSvgStyle imageOrder={1} image={smileImg} imageSize={[42, 42]} circleColor={["#0077FF", "#84BEFF", "#BEDBFD"]} text={"Well done, Continue!"} textSize={30} />
                <div className="forgotForm">
                    <InputBox type='email' name='email' placeholder='Please enter your email' inputLabel='Email' inputValue={_props?.forgotPassword.email} inputParse={_props?.forgotPassword}
                        onInputChange={_props?.setForgotPassword}
                    />

                    <button className="submitBtn">
                        Get OTP
                    </button>
                </div>

                <FormPagesSvgStyle imageOrder={2} rotation={180} image={qritLogo} imageSize={[80, 80]} circleColor={["#0077FFF0", "#00AEB93D", "#020C0D12"]} text={"Made with love ❤️"} textSize={15} />

            </form>
        </>
    )
}

export default Body