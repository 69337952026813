import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Login from './Pages/Login/Index'
import "./App.css"
import Signup from './Pages/Signup/Index'
import Forgot from './Pages/Forgot/Index'
import OTP from './Pages/OTP/Index'
import PasswordReEnter from './Pages/PasswordReenter/Index'
import ChooseSupplier from './Pages/ChooseSupplier/Index'
import UserHome from './Pages/Home/Index'
import Notification from './Pages/Notification/Index'
import History from './Pages/History/Index'
import GetHelp from './Pages/GetHelp/Index'
import ProfileEdit from './Pages/ProfileEdit/Index'
import DuePayments from './Pages/DuePayments/Index'
import UsersList from './Pages/UserList/Index'
import UserProfile from './Pages/UserProfile/Index'
import QrcodeScanner from './Pages/QrScanner/Index'
import Logout from './Pages/Logout'
const App = () => {

  const token = document.cookie !== "" ? document.cookie.split(";")[0].split("token=")[1] : ""
  const userId = document.cookie !== "" ? document.cookie.split(";")[1].split("id=")[1] : ""
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={(token === "" && userId === "") ? <Login /> : <Navigate to={"/"} />} />
        <Route path='/signup' element={(token === "" && userId === "") ? <Signup /> : <Navigate to={"/"} />} />
        <Route path='/forgot' element={(token === "" && userId === "") ? <Forgot /> : <Navigate to={"/"} />} />
        <Route path='/otp' element={(token === "" && userId === "") ? <OTP /> : <Navigate to={"/"} />} />
        <Route path='/changepassword' element={(token === "" && userId === "") ? <PasswordReEnter /> : <Navigate to={"/"} />} />
        <Route path='/help' element={(token !== "" && userId !== "") ? <GetHelp /> : <Navigate to={"/login"} />} />
        <Route path='/editprofile' element={(token !== "" && userId !== "") ? <ProfileEdit /> : <Navigate to={"/login"} />} />
        <Route path='/logout' element={<Logout />} />

        <Route path='/account'>
          <Route path='supplierchoose' element={<ChooseSupplier />} />
        </Route>
        <Route path='/' element={(token !== "" && userId !== "") ? <UserHome /> : <Navigate to={"/login"} />} />

        <Route path='/notification' element={(token !== "" && userId !== "") ? <Notification /> : <Navigate to={"/login"} />} />
        <Route path='/history' element={(token !== "" && userId !== "") ? <History /> : <Navigate to={"/login"} />} />
        <Route path='/dues' element={(token !== "" && userId !== "") ? <DuePayments /> : <Navigate to={"/login"} />} />
        <Route path='/customers' element={(token !== "" && userId !== "") ? <UsersList /> : <Navigate to={"/login"} />} />
        <Route path='/scanner' element={(token !== "" && userId !== "") ? <QrcodeScanner /> : <Navigate to={"/login"} />} />
        <Route path='/customers'>
          <Route path='profile' element={(token !== "" && userId !== "") ? <UserProfile /> : <Navigate to={"/login"} />} />
        </Route>
        <Route path='*' element={<NotFound />} />

      </Routes>
    </BrowserRouter>
  )
}

export default App

const NotFound = () => {
  return (
    <h3>Not Found</h3>
  )
}