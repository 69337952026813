import React, { useEffect, useState } from 'react'
import Body from './Body'
import axios from 'axios'
import toast from 'react-hot-toast'
import auth from '../../Routes/Auth/Auth'

const ProfileEdit = () => {
    const token = document.cookie !== "" ? document.cookie.split(";")[0].split("token=")[1] : ""
    const userId = document.cookie !== "" ? document.cookie.split(";")[1].split("id=")[1] : ""

    const [updateProfile, setUpdateProfile] = useState(null)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        axios.get(auth.getProfile + "/" + userId, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((res) => {
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                setUpdateProfile({
                    name: res.data.data.name,
                    distributorId: res.data.data._id,
                    storeName: res.data.data.storeName,
                    email: res.data.data.email,
                    phone: res.data.data.phone,
                    wpNumber: res.data.data.wpNumber,
                    price: res.data.data.price,
                    Qty: res.data.data.Qty,
                    address: res.data.data.address,
                    pincode: res.data.data.pincode
                })
            }
        }).catch((err) => {
            console.log(err);
        })
    }, [token, userId])

    const updateSupplierProf = (e) => {
        e.preventDefault();
        setLoader(true)
        axios.put(auth.profileUpdate, updateProfile, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((res) => {
            setLoader(false)
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                toast.success(res.data.message)
            }
        }).catch((err) => {
            setLoader(false)
            toast.error("Please try again later")
            console.log(err);
        })
    }

    const _this = { updateProfile, setUpdateProfile, updateSupplierProf,loader }
    return (
        <Body {..._this} />
    )
}

export default ProfileEdit