import React from 'react'
import PropTypes from 'prop-types'
import "../Assets/Style/Pages/FilterWaterDetails.css"
import { IoCalendarOutline } from "react-icons/io5"
const FilterWaterDetails = _props => {
    const today = new Date();
    return (
        <div className="filterWaterDetails">
            <div className="filterWaterDetailsInnerSec">


                <div className="cardDateFilterHeader">
                    <label htmlFor="filterDate" className='monthViewLabel'>
                        {
                            (_props.date === "" || new Date(_props?.data).getMonth() === today.getMonth()) ? "This Month" : new Date(_props?.date).toLocaleString('default', { month: 'long' })
                        }
                    </label>
                    <div className="filterDate">
                        <input type="date" id='filterDate' onChange={(e) => _props.dateChange(e.target.value)} value={_props?.dateChange} placeholder={""} />
                        <button className='dateToggleBtn'><IoCalendarOutline size={35} color='gray' /></button>
                    </div>
                </div>

                <div className="measurementCards">
                    <div className="mesurementCard">
                        <div className="measureNumber">{_props?.qty}</div>
                        <div className="measureUnit">p</div>
                    </div>
                    <div className="mesurementCard">
                        <div className="measureNumber">{_props?.amount}</div>
                        <div className="measureUnit">₹</div>
                    </div>

                </div>


            </div>
        </div>
    )
}

FilterWaterDetails.propTypes = {
    dateChange: PropTypes.func.isRequired,
    date: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    qty: PropTypes.number.isRequired,

}

export default FilterWaterDetails