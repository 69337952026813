import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import FormPagesSvgStyle from '../../Components/FormPagesSvgStyle'
import qritLogo from "../../Assets/Images/Qrit logo transparent.png"
import PropTypes from "prop-types"
import ActiveLoader from "../../Components/ActiveLoader"
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
const Body = (_props) => {
    const navigate = useNavigate();

    const [otpInput, setOtpInputs] = useState(new Array(_props?.length).fill(""))
    const otpInputRef = useRef([])

    // handle onchange in the input tag and chane into the next 
    const handleOtpInputChange = (elm, index) => {
        let value = elm.target.value;
        // if (!Number(value)) return; //this line throw an error in otp input field (0)
        const otpSubstractor = [...otpInput]
        otpSubstractor[index] = value.substring(value.length - 1)
        setOtpInputs(otpSubstractor)
        if (value && index < _props?.length - 1 && otpInputRef.current[index + 1]) {
            otpInputRef.current[index + 1].focus()
        }





        // do not remove this commented code 
        // if (otpInput[index - 1] === "" && index > 0 && index < _props?.length) {
        //     console.log("fill first")
        //     blankBox = index - 1
        //     otpInputRef.current[index - 1].classList.add("otpInvalidinput")
        //     return;
        // }
        // console.log(blankBox);

    }

    // handle to clean the state and also the focus into previous
    const otpKeyDownHandler = (key, index) => {
        if (key.keyCode === 8 && index >= 0) {
            const otpSubstractor = [...otpInput]
            otpSubstractor[index] = ""
            setOtpInputs(otpSubstractor)
            if (otpInputRef.current[index - 1]) {

                otpInputRef.current[index - 1].focus()
            }
        }
    }

    // const handleClickOnInput = (index) => {
    //     otpInputRef.current[index].setSelectionRange(1, 1)
    // }

    useEffect(() => {
        otpInputRef.current[0].focus()
    }, [])


    const submitOtp = () => {
        let output = _props?.forgotDetails
        output = {
            ...output,
            otp: otpInput.join("")
        }
        _props?.setLoader(true)

        _props?.forgotDetails.type === "forgetPass" ? navigate("/changepassword", { state: { ...output } }) :

            axios.post(_props?.forgotDetails.type === "DistributorRegistration" ? auth.signup : auth.forgotPassword, output).then((res) => {
                // console.log(res);
                _props?.setLoader(false)
                if (res.status === 202) {
                    toast.error(res.data.error)
                } else {
                    toast.success(res.data.message)
                    navigate("/login");
                }
            }).catch((err) => {
                _props?.setLoader(false)
                console.log(err);
            })


    }
    return (
        <>
            <Toaster />
            <ActiveLoader loaderStatus={_props?.loader} />
            <div className="otpSection">
                <Header headerLabel='Back' />
                <div className="otpSectionPadding">

                    <div className="headerLabels">
                        <label htmlFor="subHeader">We have sent an OTP at the following email address:</label>
                        <label htmlFor="subHeaderGamil">{_props?.forgotDetails.email}</label>
                    </div>
                    <div className="otpInputsSec">
                        {
                            otpInput.map((data, index) => {
                                return <input
                                    type="number"
                                    ref={(inputs) => (otpInputRef.current[index] = inputs)}
                                    className='otpInputs'
                                    value={data}
                                    onChange={(e) => handleOtpInputChange(e, index)}
                                    onKeyDown={(e) => otpKeyDownHandler(e, index)}
                                // onClick={() => handleClickOnInput(index)}
                                />
                            })
                        }
                    </div>
                    {/* <div className="formError">
                        Invalid Otp
                    </div> */}

                    <button className="submitBtn"
                        onClick={() => submitOtp()}
                    >Verify</button>
                    <div className="resendOtpSec">
                        <label htmlFor="recivedAgain">Didn’t receive an OTP?</label>
                        <button className='resendOtp' type='button' onClick={() => _props?.ResendOtp()}>Resend</button>
                    </div>
                </div>
                <FormPagesSvgStyle text='Made with love ❤️' textSize={14} rotation={180} imageOrder={1} imageSize={["90px", "90px"]} circleColor={["#0077FFF0", "#00AEB93D", "#020C0D12"]} image={qritLogo} />
            </div>
        </>
    )
}

export default Body


Body.propTypes = {
    forgotDetails: PropTypes.object.isRequired,

}