import React, { useState, useRef, useCallback, useEffect } from 'react';
import { BrowserMultiFormatReader } from '@zxing/library';
import { useNavigate } from 'react-router-dom';
import Body from './Body';
import axios from 'axios';
import auth from '../../Routes/Auth/Auth';
import toast from 'react-hot-toast';

const QrcodeScanner = () => {
    const token = document.cookie !== "" ? document.cookie.split(";")[0].split("=")[1] : "";
    const userId = document.cookie !== "" ? document.cookie.split(";")[1].split("=")[1] : "";

    const navigate = useNavigate();

    const [data, setData] = useState({
        userId: "",
        distributorId: ""
    });
    const [error, setError] = useState(null);
    const [openModel, setOpenModel] = useState(false)

    const webcamRef = useRef(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const codeReader = new BrowserMultiFormatReader();

    const capture = useCallback(async () => {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
            try {
                const result = await codeReader.decodeFromImage(undefined, imageSrc);
                // const qrData = ;

                let output = JSON.parse(result.text)
                output = {
                    ...output,
                    distributorId: userId
                }
                axios.post(auth.qrScanner, output, {
                    headers: { "Authorization": `Bearer ${token}` }
                }).then((res) => {
                    if (res.status === 202) {
                        toast.error(res.data.error);
                        setTimeout(() => {
                            navigate("/");
                        }, 1500);
                    } else {
                        // toast.success('QR code processed successfully!');
                        setOpenModel(true)
                        setTimeout(() => {
                            navigate("/");
                        }, 1500);
                    }
                }).catch((err) => {
                    toast.error('An error occurred while processing the QR code.');
                });

                setError(null);
            } catch (err) {
                if (err.name === 'NotFoundException') {
                    setError('No QR code detected. Please try again.');
                } else {
                    setError(`Error: ${err.message}`);
                }
            }
        } else {
            setError('Failed to capture image. Please ensure the camera is working.');
        }
    }, [codeReader, navigate, token]);

    useEffect(() => {
        const interval = setInterval(capture, 1000); // 1-second interval
        return () => clearInterval(interval);
    }, [capture]);

    const videoConstraints = {
        facingMode: { exact: 'environment' },
        width: 320,
        height: 240
    };

    const _this = {
        webcamRef, videoConstraints, error, data, openModel, setOpenModel
    };

    return (
        <Body {..._this} />
    );
};

export default QrcodeScanner;
