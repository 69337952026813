import React, { useEffect, useState } from 'react'
import Body from './Body'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'

const DuePayments = () => {
    const token = document.cookie !== "" ? document.cookie.split(";")[0].split("token=")[1] : ""
    const userId = document.cookie !== "" ? document.cookie.split(";")[1].split("id=")[1] : ""

    const [pendingTrans, setPendingTrans] = useState(null)
    const [loader, setLoader] = useState(false)
    const handelPendingTransaction = () => {
        axios.post(auth.pendingTransaction, { distributorId: userId }, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((res) => {
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                setPendingTrans(res.data.data)
            }
        }).catch((err) => {

            toast.error("Please try again later")
            console.log(err);
        })
    }
    useEffect(() => {
        handelPendingTransaction()
    }, [])
    const UpdateDues = (_id) => {
        setLoader(true)
        axios.put(auth.updatePendingDues, { userId: _id, distributorId: userId }, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then((res) => {
            setLoader(false)
            console.log(res);
            if (res.status === 202) {
                toast.error(res.data.error);
            } else {
                toast.success(res.data.message)
                handelPendingTransaction();
            }
        }).catch((err) => {
            setLoader(false)
            console.log(err);

        })


    }
    const _this = { pendingTrans, UpdateDues, loader }
    return (
        <Body  {..._this} />
    )
}

export default DuePayments