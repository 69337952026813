import React, { useState } from 'react'
import "../Assets/Style/Components/InputBox.css"
import PropTypes from 'prop-types';

import { FaEye, FaEyeSlash } from "react-icons/fa"
const InputBox = (_props) => {
  const [passShow, setPassShow] = useState(false)
  const passwordViewer = (e) => {
    const passwordInput = e.parentNode.parentNode.children[0]
    setPassShow(!passShow)
    passwordInput.type === "password" ? passwordInput.type = "text" : passwordInput.type = "password"
    return;
  }
  return (
    <div className="inputBox">
      {
        (_props?.type !== "select" && _props?.longText === false) ?
          <input
            key={_props?.boxKey}
            name={_props?.name}
            type={_props?.type}
            placeholder={_props?.placeholder}
            onChange={(e) => _props?.onInputChange({ ..._props?.inputParse, [e.target.name]: e.target.value })}
            value={_props?.inputValue}
            className={_props?.type === "password" ? "passwordInput inputItem" : "inputItem"}
            required={_props?.isrequired}
            minLength={_props?.minLength === "" ? "" : _props?.minLength}
          /> : ""
      }
      {
        _props?.longText ?
          <textarea

            key={_props?.boxKey}
            className='inputItem inputBoxTextarea'
            name={_props?.name}
            type={_props?.type}
            placeholder={_props?.placeholder}
            onChange={(e) => _props?.onInputChange({ ..._props?.inputParse, [e.target.name]: e.target.value })}
            value={_props?.inputValue}
            required={_props?.isrequired}
            minLength={_props?.minLength === "" ? "" : _props?.minLength}
          >

          </textarea> : ""
      }
      {
        _props?.type === "select" ?
          <select name={_props?.name}
            value={_props?.inputValue}
            onChange={(e) => _props?.onInputChange({ ..._props?.inputParse, [e.target.name]: e.target.value })}
            required={_props?.isrequired}
            className='inputItem'

            key={"textArea"}
          >

            <option value="">Select :</option>
            {typeof (_props?.selectItems) === "object" && _props?.selectItems.length > 1 ?
              _props?.selectItems.map((data) => {
                return <option value={data}>{data}</option>
              }) : ""
            }
          </select>
          : ""
      }
      <label htmlFor={_props?.inputLabel}>{_props?.inputLabel}</label>
      {
        _props?.icon === null ?
          _props?.isPassword === true ?
            <button type='button' className='inputPostIcon' onClick={(e) => passwordViewer(e.target)}>

              {
                passShow ?
                  <FaEye size={25} fill='gray' />
                  :

                  <FaEyeSlash size={25} fill='gray' />
              }
              <span></span>
            </button>
            : "" :
          <button className='inputPostIcon'>
            {_props?.icon}
          </button>
      }
    </div>
  )
}

export default InputBox

InputBox.defaultProps = {
  isPassword: false,
  isrequired: false,
  minLength: "",
  icon: null,
  selectItems: {},
  longText: false
}

InputBox.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  inputParse: PropTypes.object.isRequired,
  inputLabel: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired

}

