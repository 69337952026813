import { React, useState } from 'react'
import "../../Assets/Style/Pages/otp.css"
import Body from './Body'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import auth from '../../Routes/Auth/Auth'
import toast from 'react-hot-toast'

const OTP = () => {
    const location = useLocation()
    // const navigate = useNavigate()
    const [loader, setLoader] = useState(false)

    const [forgotDetails, setForgotDetails] = useState(location.state)
    const length = 4
    const ResendOtp = () => {
        setLoader(true)
        axios.post(auth.resendOtp, { email: forgotDetails.email }).then((res) => {
            setLoader(false)
            if (res.status === 202) {
                toast.error(res.data.error)
            } else {
                toast.success(res.data.message)
            }
        }).catch((err) => {
            setLoader(false)
            console.log(err);
        })
    }
    const _this = {
        forgotDetails, setForgotDetails,
        length, ResendOtp, loader, setLoader

    }
    return (
        <Body {..._this} />
        // <Body />
    )
}

export default OTP